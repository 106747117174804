import React from 'react'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from "react-loader-spinner";
import Chat from './Chat';
const stories = require("./stories.json");

export default function Story() {
    let { id } = useParams();
    const [theStoryInstance, setTheStoryInstance] = useState({});
    const [theChats, setTheChats] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{

        let theParticularStory;

        for(let i=0; i<stories.length; i++)
        {
            if(stories[i].id == id)
            {
                setTheStoryInstance(stories[i]);
                theParticularStory = stories[i];
                break;
            }
        }

        let theChatBubbles = [];
        for(let i=0; i<theParticularStory.story.length; i++)
        {
            theChatBubbles.push(<Chat className="text-left" key={i} message={theParticularStory.story[i]} />);
        }
        setTheChats(theChatBubbles);
        setIsLoading(false)
    }, []);
    
    return (
        <div style={{height: "100vh", width: "100vw"}} className='storyBack ' >
            <div className="container container-fluid text-center storyBack2 align-middle ">
                <div className="row ">
                    <h2 className='storyPageTitle'>{theStoryInstance.title}</h2>
                </div>
                <div className="row ">
                    <div className="container container-fluid chatSection" style={{overflowY: "auto"}}>
                        {theChats.map((ele)=>ele)}
                    </div>
                </div>
            </div>
        </div>
    )
}
