import React from 'react'
import { useState, useEffect } from 'react';
import "./styles.css";
import ThumbnailImage from './ThumbnailImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStepForward, faStepBackward, faRandom } from '@fortawesome/free-solid-svg-icons'
const playlist = require("./playlist.json");

const  shuffleArray = (array) => {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
}
  


export default function Playlist() {

    const [thePlaylist, setThePlaylist] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setThePlaylist(shuffleArray(playlist));
    }, []);

    const songEnded = () => {
        setCurrentIndex((currentIndex+1)%thePlaylist.length);
    }

    const shuffleThePlaylist = () => {
        setThePlaylist(shuffleArray(thePlaylist));
        setTimeout(
            () => setCurrentIndex(0), 
            1000
        );
    }

    const nextToggleHandler = () => {
        setCurrentIndex((currentIndex+1)%thePlaylist.length);
    }

    const prevToggleHandler = () => {
        if(currentIndex === 0)
        {
            setCurrentIndex(thePlaylist.length-1);
        }
        else
        {
            setCurrentIndex((currentIndex-1)%thePlaylist.length);
        }
    }


    return (
        <div className="" style={{overflowX: "auto", overflowY: "auto", height: "100vh", width: "100vw", background: "url(/assets/songsBG.jpg)"}}>
            <div className="container container-fluid playlistCard align-middle text-center" style={{margin: "2% auto"}}>
                <div className="row" style={{marginTop: "10px"}}>
                    <div className="col-12 text-center">
                        <div className="container container-fluid text-center audioPlayer">
                            <ThumbnailImage image={playlist[currentIndex].image} message={playlist[currentIndex].message} />
                            <p className='albumName'>{playlist[currentIndex].album}</p>
                            <p className='songName'>{playlist[currentIndex].name}</p>
                            <div className="row text-center">
                                <div className="container container-fluid">
                                    <div className="row text-center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <div className="col-12 align-middle text-center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <button className='toggleButtons' onClick={shuffleThePlaylist}>
                                                <FontAwesomeIcon className='toggleIcon' icon={faRandom} style={{margin: "1.2rem auto"}} />
                                            </button>
                                            <button className='toggleButtons' onClick={prevToggleHandler}>
                                                <FontAwesomeIcon className='toggleIcon' icon={faStepBackward} style={{margin: "1.2rem auto"}} />
                                            </button>
                                            <audio autoPlay onEnded={songEnded} controls src={playlist[currentIndex].link}> Your browser does not support the <code>audio</code> element.</audio>
                                            <button className='toggleButtons' onClick={nextToggleHandler}>
                                                <FontAwesomeIcon className='toggleIcon' icon={faStepForward} style={{margin: "1.2rem auto"}} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}