import React from 'react'
import { useState, useEffect } from 'react';

export default function Chat(props) {

    const [theMessage, setTheMessage] = useState("");

    useEffect(()=>{
        let message = props.message;
        message = message.replaceAll("ðŸ¥º", "🥺");
        message = message.replaceAll("ðŸ˜‚", "😂");
        message = message.replaceAll("ðŸ˜˜", "😘");
        message = message.replaceAll("ðŸ˜‰", "😉");

        setTheMessage(message);
    }, []);

    return (
        <div className='chatBubble'>
            <p className='theChatMessage'>{theMessage}</p>
        </div>
    );
}
