import React from 'react'
const stories = require("./stories.json");

export default function Stories() {

    const allStoriesInstances = []

    for(let i=0; i<stories.length; i++)
    {
        allStoriesInstances.push(
            <div className='container container-fluid'>
                <div className="card mx-auto thephotoEffect" style={{width: "18rem"}} onClick={()=>{window.location = `/stories/${stories[i].id}`}}>
                    <img className="img img-fluid cardImage" src={stories[i].thumbnail} alt="Card"/>
                    <div className="card-body">
                        <h5 className="card-title storyKaTitle">{stories[i].title}</h5>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="theStories" style={{height: "100vh", width: "100vw", backgroundImage: "url(./assets/storiesBG.jpg)"}}>
            <div className="container container-fluid theStories2 align-middle">
                <div className="row text-center" style={{color: "white"}}>
                    <h1 className='titleStories'>Stories!</h1>
                    <p className='somethingToRememberMe'>Something to remember me by!</p>
                </div>
                <div className="row">
                    <div className="container container-fluid theStoryInstances text-center">
                        <div className="row text-center">
                            {allStoriesInstances.map((ele, i)=><div className="col-lg-4 col-md-6 col-sm 12 text-center" key={i}>{ele}</div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
