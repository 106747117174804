import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import App from './App';
import Playlist from './playlistPage/Playlist';
import Stories from './storiesPage/Stories';
import Story from './storiesPage/Story';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}/>
      <Route path="/playlist" element={<Playlist/>} />
      <Route path="/stories" element={<Stories/>} />
      <Route path="/stories/:id" element={<Story />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);