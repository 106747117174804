import React from 'react'
import { useState, setState } from 'react';

export default function ThumbnailImage(props) {

    const [showOverlay, setShowOverlay] = useState(false);

    let theColorCombos = [
        ["#621a83", "#ffffff"],
        ["#FAFAFA", "#9D5C0D"],
        ["#121212", "#F5F5F5"],
        ["#022E57", "#FFF5FD"],
    ];
    
    let randomIndex = parseInt(Math.random()*theColorCombos.length);

    const tapToggle = () => {
        setShowOverlay(!showOverlay);
    }

    const getWidth = () => {
        try{
            return document.querySelector("#thumbnailKaImage").clientWidth;
        }
        catch{
            return document.querySelector(".overlayGlass").clientWidth;
        }
    }

    const getHeight = () => {

        try{
            return document.querySelector("#thumbnailKaImage").clientHeight;
        }
        catch{
            return document.querySelector(".overlayGlass").clientHeight;
        }
    }

    return (
        <>
        {!showOverlay ? <img src={props.image} id='thumbnailKaImage' alt="thumbnail" onClick={tapToggle} className='img img-fluid imageThumbnail' /> : 
        <div className='imageThumbnailOverlay text-center' style={{background: `url(${props.image}) no-repeat center center fixed`, width: getWidth(), height: getHeight(), overflowY: "auto", scrollbarWidth: "none"}} onClick={tapToggle}>
            <div className='overlayGlass' style={{backgroundColor: theColorCombos[randomIndex][0]}}>
                <div className=''>
                    <p className='theMessage' style={{backgroundColor: theColorCombos[randomIndex][0], color: theColorCombos[randomIndex][1]}}>{props.message}</p>
                </div>
            </div>
        </div>}
        </>
    )
}
