import './App.css';

function App() {

  const storiesHandler = () => {
    window.location = "/stories"
  }

  const playlistHandler = () => {
    window.location = "/playlist"
  }

  return (
    <div className="App" style={{height: "100vh", width: "100vw", backgroundColor: "#2e2e2e", overflowY: 'auto'}}>
      <div className='container'>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="container" style={{overflowY: 'auto'}}>
              <img className='img img-fluid landingPhoto' src="/assets/mainPage.jpg" alt="landing"/>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="container">
              <div className="container theBdayMessageWindow" style={{overflowY: 'auto', border:'#fff 2px solid', borderRadius:'25px', padding:'0 3%'}}>
                <p className='bdayMessage'>
                  Hey Anu!
                  <br />
                  Once again, a very very Happy Birthday meri jaan!
                  <br />
                  Words may fail to describe just how much you mean to me, but imma of course try. You often ask me why do I love you?
                  You keep calling yourself average...Anu, Idk why you keep doing that. Cuz idc what perfection is to you, but for me na - you're the most perfect
                  <br /><br />
                  You're this person who taught a 'Pilot' like me the joy of independence of Finance. Anu, yaar koi nahi karta re yeh. Aai-Pappa ke paison pe jeene ka guilt - you taught me baby.
                  Supporting and Motivating on why this is important! Dude, I've seen how many folks our age are re. Massive spenders of their parents money. Am so glad you aren't that and I wish to be more like you!!
                  <br /><br />
                  Anu, yaar bachpan se na, sabne giraya hain re. I lack self-confidence...bhot saara. You know this side of me and willingly fight through it all for me...Anu, koi nahi toh tu trust karti re ki yeh ho jaayega..
                  'I know tu kar lega'. It's your confidence and motivation ki I now wanna do what I love so fearlessly. Thanks for being mine, honey. Nothing can be as powerful as having you by my side...I imagine you hugging me tight in a rough situation and it calms we down and gets me back.
                  Anu, aap bhot amazing ho! Slowly slowly, am gaining that confidence within...all thanks to you!
                  <br /><br />
                  You know how you always tell people how it was me jiski wajah se you got into development, started working for your career, etc...? Idk if it's true, but Anu - life me na sahi me kisine itne pyaar se gratefullness nahi dikhaai re. You always wholeheartedly thank and praise me re...Anu, you make me feel really really special in that moment!
                  Can't explain how much it means re. I wanna keep pushing you and me to attain great heights yaar. Fir tu aur mein...the #powercouple. You make everything count and take legit advices to the heart. I learnt hardwork and consistency from you bae! Don't ever change this about you!
                  Also, you're an amazing Lead and an amazing leader! So don't be afraid to anything...you always have me too!
                  <br /><br />
                  Anu, yaar sahi me, aisa har koi nahi karta re. You being you is exactly what I wanted from my partner. Support, Understanding and Love. Anu, sach me re, we had our set of problems, but we both joined in like that missing puzzle piece, jo milne pe ek BEAUTIFUL tasveer ban jaati.
                  I can write a 1000 reasons why Devang falls for Anuja...and keeps falling every day. She's just that awesome! She may be a Harry Potter for the world, but for me she's my Sheldon to Amy 💖
                  <br /><br />
                  Thanks for giving a meaning to the life of this depressed soul. He loves you more than anything and he thanks you immensely for being by his side. He sincerly apologises for all the times he's hurt her and wants to become the BEST version of himself for you.
                  Thanks Anu...for being his lifeline, he'll love you till eternity.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="container">
              <div className="row">
                <p className='happyBirthday align-middle' style={{verticalAlign: 'middle'}}>Happiest Birthday Anu!</p>
              </div>
              <div className="row">
                <div className="container">
                  <div className='theGiftMessageWindow'  style={{overflowY: 'auto',border:'#fff 2px solid', borderRadius:'25px', padding:'0 3%'}}>
                    <p className='giftMessage'>
                      So, I wanted to gift you something that would always remain! Something that we can preserve and maybe even keep improving. So, of course - a website (app is your game jaan, won't come in between).
                      <br />
                      So, here's this site, dedicated to us and our things! Made a playlist of our songs with an audio player with OUR flair! Hope we keep adding more to them! Each song, I could remember how we reacted listening to it, the dedications and the smiles...uff! Hope you enjoy them through this as well!
                      <br />
                      Another is the STORIES! You keep saying how you love them na! Anu, I wanna write more and then future me even experience and live through together. Plan was to compile stories together - long ones, without the 'to be continued's. I planned to write many, but aaj tak ban nahi paaya. Promise ki will keep adding them there!
                      Hope you enjoy these Anu. Ik, it ain't much, but I felt its a perfect present for the uniqueness that is us. 💖
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="container align-middle">
              <div className="row align-middle">
                <div className="container align-middle" style={{alignItems:'center'}}>
                  <button onClick={playlistHandler} style={{verticalAlign: 'middle'}} className="lb landingButtons1 align-middle">Playlist</button>
                </div>
              </div>
              <div className="row">
                <div className="container align-middle" style={{alignItems:'center'}}>
                  <button onClick={storiesHandler} style={{verticalAlign: 'middle'}} className="lb landingButtons2 align-middle">Stories</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
